
h1, h2, h3, h4, h5, h6 {
	margin:0 0 0.5rem 0;
  line-height:1.5em;
}

h1 {
	font-size:2.5rem;
}

h2 {
	font-size:1.5rem;
}

h3 {
	font-size:1.3rem;
	letter-spacing: 1px;
	font-weight:100;
	margin:4rem 0 2rem 0;
}

h4{
	font-size:1rem;
	font-weight:400;
	text-transform: uppercase;
	letter-spacing: 2px;
}
