// Misc Vars
$max-width: 1400px;
$max-width-narrow: 800px;
$font-size-base: 15px;
$font-weight-base: 200;
$line-height-base: 1.6rem;
$header-height: 75px;
$input-height: 50px;
$button-height: 40px;
$vmargin: 10rem;

// Google Material Grey's
$color-grey-50: #FAFAFA;
$color-grey-100: #F5F5F5;
$color-grey-200: #EEEEEE;
$color-grey-300: #E0E0E0;
$color-grey-400: #BDBDBD;
$color-grey-500: #9E9E9E;
$color-grey-600: #757575;
$color-grey-700: #616161;
$color-grey-800: #424242;
$color-grey-900: #212121;

// Brand Colors
$brand-blue-dark: #11263C;
$brand-blue-medium: #5A5A6F;
$brand-blue-bright: #4E4E66;
$brand-blue-light: #ACACB7;
$brand-pink: #E6007E;
$color-body: $brand-blue-dark;
$color-map-water: #ecf3f5;

// CNY Colors
$cny-red-100: #c22d2b;
$cny-red-200: #B2231D;
$cny-red-300: #750000;
$cny-yellow-100: #e3c56f;
$cny-yellow-200: #B68540;
$cny-body: #E6CC7A;

// Breakpoints
$ptab: new-breakpoint(601px);
$tab: new-breakpoint(769px);
$laptop: new-breakpoint(992px);
$desk: new-breakpoint(1025px);
$wideDesk: new-breakpoint(1201px);
$wideMax: new-breakpoint(1401px);
$mobileOnly: new-breakpoint(max-width 600px);
$ptabAndLess: new-breakpoint(max-width 768px);
$laptopHeightOnly: new-breakpoint(max-height 750px);

// Fonts
$dir: "../fonts/first_light_seattle-24072018/Fonts/";

@font-face {
    font-family: "UniversCondensed";
    font-weight: 200;
    src:url("#{$dir}30d23194-f646-428e-9323-c85f8aea47ed.eot?#iefix");
    src:url("#{$dir}30d23194-f646-428e-9323-c85f8aea47ed.eot?#iefix") format("eot"),
    url("#{$dir}58c01d2a-ce4a-4360-a61f-761e91aa67c0.woff2") format("woff2"),
    url("#{$dir}ccd9698a-60c5-48f7-acbe-5352b4aa81d4.woff") format("woff"),
    url("#{$dir}ba8ec1ac-e90e-4cea-bda8-85f718fb8fd0.ttf") format("truetype");
}

@font-face {
    font-family: "UniversCondensed";
    font-weight: 400;
    src:url("#{$dir}4a916908-f6f7-4df6-90a1-176fb83137d9.eot?#iefix");
    src:url("#{$dir}4a916908-f6f7-4df6-90a1-176fb83137d9.eot?#iefix") format("eot"),
    url("#{$dir}bf8f8741-5f64-4db9-a877-a44624092e68.woff2") format("woff2"),
    url("#{$dir}7ce02c2c-45d4-4dee-90f2-f8034e29ac24.woff") format("woff"),
    url("#{$dir}0955c906-88fc-47e8-8ea2-0765bdf88050.ttf") format("truetype");
}

$font-sans-serif: 'UniversCondensed', 'Helvetica Neue', sans-serif;