
*, *:before, *:after {
	box-sizing: inherit;
}
html {
	box-sizing: border-box;

	&.no-scroll{
		overflow:hidden;

		body{
			overflow:hidden;
		}
	}
}
body {
	font-family:$font-sans-serif;
	font-weight:$font-weight-base;
	font-size:$font-size-base;
	line-height:$line-height-base;
	color:$color-body;
}
a{
	color:$brand-blue-light;

	&:hover{
		color:$brand-blue-dark;
	}
}
p{
	margin:0 0 1.25rem 0;

	&.display{
		font-size:1rem;
		line-height:1.75em;

		@include media($ptab) {
			font-size:1.25rem;
		}
	}
}
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
ol{
	display: inline-block;
	margin:0;
	padding:0 0 0 1rem;
}
button{
	border:none;
	padding:0;
	margin:0;
	display:inline-block;
	cursor:pointer;
	background:none;
	outline:none;
	font-weight:200;
}
figure{
	margin:0;
	padding:0;
}
main{
	display: block;
	position: relative;
	padding:15rem 0 0 0;
}
blockquote{
	margin:1.25em 0;
	font-weight:100;
	font-size:1rem;
	line-height:1.5em;

	@include media($ptab) {
		font-size:1.25rem;
		line-height:1.75em;
	}
}




// Global Styles
// -----------------------------------
.bg-image{
	position: absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:0;
	background-size:cover;
	background-position:center;
	background-repeat:no-repeat;
	transition:all .5s ease;
	transform: translate3d(0,0,0);
}

.reveal-hidden{
	opacity:0;
	transform:translate3d(0,50px,0);
}

.reveal-show{
	transition:
		transform 1s,
		opacity 1.5s
	;
	opacity:1;
	transform:translate3d(0,0,0);
}

.alignleft {
  @include media($tab) {
		float: left;
	}
}

.alignright {
	@include media($tab) {
		float: right;
	}
}

.smaller{
	font-size:0.6rem;
	line-height:1.5em;
	margin-top:0.8rem;

	@include media($ptab) {
		font-size:0.8rem;
	}
}

.wp-caption{
	width:100% !important;

	img{
		width:100%;
		height:auto;
	}
	.wp-caption-text{
		font-size:0.8rem;
		max-width:400px;
	}
}

.inner-wrap{
	width:85%;
	max-width:$max-width;
	margin:0 auto;
	position: relative;
}

.narrow-wrap{
	width:85%;
	margin:0 auto;
	position: relative;
	max-width:$max-width-narrow;
}

.column--wrapper{
	display: flex;
	flex-wrap:wrap;
	justify-content: space-between;
}

.column--title{
	flex:0 0 100%;
	display: flex;
	flex-wrap: wrap;
	margin:0 0 0 0;

	@include media($ptab) {
		justify-content: space-around;
		margin-bottom:6rem;
	}

	.logo-firstlight{
		flex:0 0 20%;
	}
	.center--wrapper{
		flex:0 0 100%;

		@include media($ptab) {
			flex:0 0 40%;
		}
	}
	.column--spacer{
		flex:0 0 20%;
	}

	h1,h2{
		font-size:1.5rem;
		line-height:1em;
		letter-spacing: 1px;
		color:$brand-blue-dark;
		font-weight:100;
		margin:0 0 2rem 0;

		@include media($ptab) {
			margin:0;
		}
	}
}

.column--left{
	flex:0 0 100%;

	@include media($ptab) {
		flex:0 0 20%;
	}

	h1{
		font-size:1.75rem;
		font-weight:200;
		letter-spacing: 2px;
		margin:0 0 3rem 0;
	}

	.meta-data{
		font-weight:500;
	}

	figure{
		width:100%;
		margin:0 0 2rem 0;

		img{
			display: block;
			width:100%;
			height:auto;
			margin:0;
		}
	}

	nav{
		button,
		a{
			display: block;
			font-size:1.4rem;
			line-height:1.5em;
			color:$brand-blue-light;
			margin:0 0 1rem 0;
			letter-spacing: 1px;
			text-decoration: none;

			&:hover,
			&.active{
				color:$brand-blue-bright;
			}
		}
	}
}

.column--mid{
	flex:0 0 100%;
	color:$brand-blue-bright;
	line-height:1.9em;
	font-size:1rem;

	@include media($ptab) {
		flex:0 0 40%;
	}

	img{
		width:100%;
		height:auto;
		display: block;
		margin:0;
	}
}

.column--right{
	flex:0 0 100%;

	@include media($ptab) {
		flex:0 0 20%;
	}

	h2{
		font-size:1rem;
		margin:0 0 1rem 0;
		font-weight:$font-weight-base;
	}
}




.inline-video-player{
	position: relative;
	overflow:hidden;
	margin-bottom:2rem;

	&.fade-cover{
		.bg-image{
			opacity:0;
		}
	}
	&.is-playing{
		video{
			z-index:3;
		}
	}

	.video-wrapper{
		position: relative;
		padding-bottom: 56.25%;
	}

	video{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index:1;
		background:black;
	}
	.bg-image{
		z-index:2;
		transition:opacity 1s ease;
	}
}




.video--play{
	position: fixed;
	top:50%;
	left:50%;
	z-index:50;
	background:rgba($brand-blue-dark,0.8);
	border-radius:100%;
	display:flex;
	justify-content: center;
	align-items: center;
	width:70px;
	height:70px;

	&.hidden{
		display: none;
	}

	span{
		display: inline-block;
		color:white;
		text-transform:uppercase;
		font-size:0.6rem;
		line-height:1em;
		font-weight:400;
		letter-spacing: 2px;
	}
}





.popup--msg{
	position: fixed;
	bottom:0;
	left:0;
	width:100%;
	background:$color-grey-200;
	color:$brand-blue-dark;
	text-align:center;
	padding:1rem;
	z-index:80;
	transition:all 1s ease;
	transform: translate3d(0,0,0);

	&.offcanvas{
		bottom:-100%;
	}

	a{
		color:$brand-blue-dark;
	}
	p{
		margin-bottom:1rem;

		&:last-child{
			margin:0;
		}
	}
	.btn-close{
		text-transform: uppercase;
		font-size:0.7rem;
		letter-spacing: 2px;
		color:$color-grey-500;
	}
}




.section--title{
	display: block;
	width:100%;
	border-top:solid 1px $color-grey-300;
	padding:1rem 0 0 0;
	font-size:1.3rem;
	margin:0 0 3rem 0;
	letter-spacing: 1px;
	font-weight:100;
	color:$brand-blue-dark;
	line-height:1.5em;

	&.less-margin {
		margin-bottom:1rem;
	}
	&.no-border{
		border:none;
	}
}





.nav--dropdown{
	position: relative;
	display: inline-block;
	transition: opacity .5s ease;
	margin:0 0 2rem 0;
	z-index:50;

	@include media($ptab) {
		margin-bottom:5rem;
	}
	@include media($tab) {
		margin-top:1.25rem;
	}

	&-dropdown{
		position: relative;
		display: inline-block;

		&:hover{
			.nav--dropdown-list{
				display: block;
			}
		}
	}

	&-label{
		display: block;
		color:$brand-blue-dark;
		position: relative;
		padding:0 2rem 0 0;
		font-size: 1.5rem;
		line-height: 1em;
		letter-spacing: 1px;
		font-weight: 100;

		i{
			vertical-align: middle;
			position: absolute;
			top:50%;
			right:0;
			transform: translate3d(0,-50%,0);
		}
	}
	&-list{
		position: absolute;
		top:60%;
		left:-1rem;
		width:calc(100% + 2rem);
		background:white;
		display: none;
		box-shadow:0 5px 10px 0 rgba(black,0.05);

		a{
			display: block;
			text-decoration: none;
			padding:10px 1rem;
			letter-spacing: 1px;
			font-size:1.1rem;

			&:hover{

			}
		}
	}

	&-parent_title{
		display: block;
		margin:4px 0 0 0;
		text-transform: uppercase;
		font-size:0.7rem;
		letter-spacing: 2px;
		color:$color-grey-500;
		opacity:0;
	}
}




[data-reveal="hidden"]{
	opacity:0;
}
[data-reveal="show"]{
	opacity:1;
	transition:opacity .5s ease .25s;
	transform: translate3d(0,0,0);
}



[data-gallery-image]{
	cursor:pointer;
}








body.page-id-220{
	header{
		display: none;
	}
	footer{
		background:$brand-blue-dark;
		margin:0;
	}
}

main.splash{
	background:$brand-blue-dark;
	overflow:hidden;
	color:$brand-blue-light;

	.splash-cover{
		height:100vh;

		.inner-wrap{
			height:100%;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
		}

		&__top{
			display: flex;
			flex:0 0 100%;

			svg{
				flex:0 0 190px;
				height:64px;
				margin:0 2rem 0 0;

				.light{
					polygon,
					rect,
					path{
						fill:white;
					}
				}
			}
			aside{
				p{
					font-size:0.65rem;
					line-height:1.5em;
					margin-bottom:6px;
					letter-spacing: 0.5px;

					&.white{
						color:white;
					}
				}
			}
			.btn{
				margin-left:auto;
				display: none;

				@include media($ptab) {
					display: block;
				}
			}
		}
		&__bottom{
			flex:0 0 100%;

			.bodymovin-container{
				height:70vh;
				width:100%;
				position: relative;
				overflow:hidden;
			}
		}
	}

	.event-messaging{
		margin:4rem 0;

		p{
			font-size: 1.3rem;
    	line-height: 1.75em;
			margin:0 auto;
			max-width:680px;
		}
	}

	#register{
		margin:0 auto 5rem auto !important;

		.section--title{
			color:$brand-blue-light;
			border-color:$brand-blue-light;
		}

		form{
			label,
			span{
				color:$brand-blue-light;
			}
			hr{
				background:$brand-blue-light;
			}
		}
	}
}





.scrollnav{
	display: none;
	margin:1rem 0 0 0;

	@include media($tab) {
		margin:0;
	}

	&.show{
		display: block;
	}

	button{
		display: inline-block;
		color:$brand-blue-light;
		margin:0 1rem 0 0;

		@include media($tab) {
			margin:0 0 0 1rem;
		}

		&:hover{
			color:$brand-blue-medium;
		}
	}
}





.sidebar{
	section{
		margin-bottom:3rem;
	}
	.section--title{
		margin-bottom:1rem;
		padding:0;
	}
	a{
		display: block;
		text-decoration: none;
	}
}





.inline-video{
	cursor:pointer;
	background:black;
	padding-top:56.25%;
	position: relative;
	width:100%;

	&:hover{
		video{
			opacity:0.5;
		}
		i{
			transform: translate3d(0,0,0) scale(1.25);
		}
	}

	&.is-playing{
		video,
		i{
			display: none;
		}
		iframe{
			opacity:1;
		}
	}

	video{
		position: absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:1;
		transition:all 1s ease;
	}

	iframe{
		width:100%;
		height:100%;
		position: absolute;
		top:0;
		left:0;
		z-index:0;
		opacity:0;
		transition:all .25s ease;
	}

	i{
		position: absolute;
		bottom:1rem;
		right:1rem;
		z-index:2;
		transform: translate3d(0,0,0);
		color:white;
		font-size:2rem;
		line-height:1em;
		transition:all .25s ease;
	}
}





.post-password-form{
	max-width:450px;
	margin:0 auto;

	p{
		display: flex;
		flex-wrap:wrap;
		align-items: flex-end;
		justify-content: space-between;
	}
	label{
		display: inline-block;
		flex:0 0 100%;
	}
	input[type="submit"]{
		flex:0 0 100%;
		display: inline-block;
		width:auto;
		margin:2rem 0 0 0;
		padding:0 2rem;
		height:$button-height;
		line-height:$button-height;
		border:solid 1px $brand-blue-dark;
		cursor:pointer;
	}
}




body.page-id-669{
	main{
		.inner-wrap{
			b{
				font-weight:bold;
			}
			ol{
				li{
					margin-bottom:2rem;

					ul{
						list-style:lower-alpha;
						margin:1rem 0 1rem 2rem;

						li{
							margin-bottom:1rem;
						}
					}
				}
			}
		}
	}
}
